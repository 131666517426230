import { EnvEnum } from "@/src/enums/common";

export type FeatureToggleEnvType = Record<EnvEnum, boolean>;
export type FeatureToggleType = boolean | FeatureToggleEnvType;

export enum FeatureToggle {
  EnableApiDebug = "enable-api-debug",
  ServerLogger = "server-logger",
  EnableUiDebug = "enable-ui-debug",
  ImageEnrichment = "image-enrichment",
  TemplateCustomAttribute = "template-custom-attribute",
  VariantableCustomAttribute = "variantable-custom-attribute",
  ActivityHistory = "activity-history",
  UrlUpload = "url-upload",
  PdpAssetsView = "pdp-assets-view",
  ViewImageDetail = "view-image-detail",
  EnrichmentApproval = "enrichment-approval",
  ListAttributeCategoryFilterFix = "list-attributes-category-filter-fix",
}

export const featureToggleConfig: Record<FeatureToggle, FeatureToggleType> = {
  [FeatureToggle.EnableApiDebug]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.ServerLogger]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.EnableUiDebug]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.ImageEnrichment]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.TemplateCustomAttribute]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.VariantableCustomAttribute]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.ActivityHistory]: {
    // BE API not ready even before v2. Hence will remain OFF.
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.UrlUpload]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.PdpAssetsView]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.ViewImageDetail]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.EnrichmentApproval]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.ListAttributeCategoryFilterFix]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
};
